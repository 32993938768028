import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import styled from "styled-components";

import BrandSectorOptions from "./BrandSectorOptions";

import getTerritoryInputOptions from "./getTerritoryInputOptions";
import fieldToJSX from "./fieldToJSX";

import getSubjectMatterOptions from "./getSubjectMatterOptions";
import { TermFormField } from "../../types";
import getTeamRightsOptions from "./getTeamRightsOptions";
import { TermType } from "../../enums";
import { formatFees } from "../../utils";

const { Option } = Select;

const SOptionLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  > span {
    width: 18px;
    height: 18px;
  }
`;

const SFieldRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TermFormFields = ({
  canWrite,
  index,
  termType,
  canCopy,
  furtherDetailsRequestedValue,
}: {
  canWrite: boolean;
  canCopy: boolean;
  index: number;
  termType?: TermType;
  furtherDetailsRequestedValue?: boolean;
}) => {
  const [timeTakenForResponseDisabled, setTimeTakenForResponseDisabled] =
    useState<boolean>();

  useEffect(() => {
    setTimeTakenForResponseDisabled(!furtherDetailsRequestedValue);
  }, [furtherDetailsRequestedValue]);

  if (index === undefined) return <></>;

  // assoc. party and threshold/databank have slightly different Summary fields
  const getSpecificSummaryFormFields = (
    termType: TermType,
  ): TermFormField[] => {
    switch (termType) {
      case TermType.associated:
        return [
          {
            name: "strategicRationaleClub",
            label: "Strategic rationale for club",
            rules: [{ required: true }],
            type: "textarea",
            maxLength: 4000,
          },
          {
            name: "strategicRationaleContractingParty",
            label: "Strategic rationale for contracting party",
            tooltip:
              "Details of the contracting party’s rationale for entering into the Transaction (e.g. key marketing objectives, key target markets and current presence in those markets, and key customer target). Please provide supporting documentation if available",
            rules: [{ required: true }],
            type: "textarea",
            maxLength: 4000,
          },
        ];
      default:
        return [
          {
            name: "strategicRationale",
            label: "Strategic rationale",
            rules: [{ required: true }],
            type: "textarea",
            maxLength: 4000,
          },
        ];
    }
  };

  const fieldsArr: TermFormField[][] = [
    [
      {
        name: "title",
        label: "Title",
        rules: [{ required: true }],
        type: "text",
      },
      {
        name: "description",
        label: "Description",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 1000,
      },
      {
        name: "agreementDate",
        label: "Date of agreement",
        tooltip: "Insert date of the agreement you are submitting.",
        style: { width: "100%" },
        rules: [{ type: "object", required: true }],
        type: "date",
      },
      {
        name: "contractingParty",
        label: "Contracting party (or parties)",
        tooltip: "Give full name of contracting party (or parties).",
        rules: [{ required: true }],
        type: "text",
      },
      {
        name: "contractingPartyJurisdiction",
        label: "Jurisdiction of contracting party",
        tooltip:
          "Please select the correct jurisdiction(s) from the drop-down menu.",
        rules: [{ required: true }],
        type: "select",
        select: {
          mode: "multiple",
          options: getTerritoryInputOptions(),
        },
      },
      {
        name: "parentContractingParty",
        label: "Parent company",
        tooltip: "Give full name of entity.",
        type: "text",
      },
      {
        name: "brand",
        label: "Brand",
        rules: [{ required: true }],
        type: "text",
      },
      {
        name: "brandSector",
        label: "Brand sector",
        tooltip:
          "Please select a sector from dropdown menu and choose 'Other' if the sector does not appear in the list.",
        rules: [{ required: true }],
        type: "cascader",
        cascader: {
          mode: "multiple",
          options: BrandSectorOptions,
        },
      },
      {
        name: "subjectMatter",
        label: "Subject matter of agreement",
        tooltip:
          "Please select from the drop-down menu - use 'Other' if subject matter does not appear in the list.",
        rules: [{ required: true }],
        type: "select",
        select: {
          options: getSubjectMatterOptions(),
        },
      },
      {
        name: "associatedParty",
        label: "Associated party",
        tooltip:
          "See definition of ‘Associated Party Transaction’ in Rule A.1.25 of the Premier League Rules, and Rules E.55 to E.59 of the Premier League Rules",
        rules: [{ required: true }],
        type: "radio",
        options: [
          {
            value: "yes",
            label: "Yes",
          },
          {
            value: "no",
            label: "No",
          },
        ],
      },
      {
        name: "armsLength",
        label: "Deal at arm's length",
        tooltip:
          "See definition of ‘Arm’s Length’ in Rule A.1.20 of the Premier League Rules",
        rules: [{ required: true }],
        type: "radio",
        options: [
          {
            value: "yes",
            label: "Yes",
          },
          {
            value: "no",
            label: "No",
          },
        ],
      },
    ],
    //tab 2, Summary
    [
      {
        name: "summary",
        label: "Summary of arrangement",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "commencementDate",
        label: "Date of commencement",
        tooltip: "State when the Transaction took effect.",
        style: { width: "100%" },
        rules: [{ type: "object", required: true }],
        type: "date",
      },
      {
        name: "expiryDate",
        label: "Date of expiry",
        tooltip: "State when the Transaction is due to expire",
        style: { width: "100%" },
        rules: [{ type: "object", required: true }],
        type: "date",
      },
      {
        name: "term",
        label: "Term",
        tooltip: "State how long the deal is due to last for.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "renewalProvisions",
        label: "Renewal provisions",
        tooltip:
          "Describe any renewal provisions or options to extend the initial/current term of the Transaction.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      ...getSpecificSummaryFormFields(termType),
    ],
    // tab 3, Fees
    [
      {
        name: "fees",
        label: "fees",
        tooltip: "fees",
        type: "format_row",
        entities: [
          {
            name: "feesTotal",
            label: "Total fees",
            rules: [{ required: true }],
            type: "number",
            number: {
              formatter: (value: number) =>
                `£ ${formatFees(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              parser: (value: string) => value.replace(/£\s?|(,*)/g, ""),
            },
          },
          {
            name: "feesCash",
            label: "(of which cash)",
            rules: [{ required: true }],
            type: "number",
            number: {
              formatter: (value: any) =>
                `£ ${formatFees(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              parser: (value: any) => value.replace(/£\s?|(,*)/g, ""),
            },
          },
          {
            name: "feesInKind",
            label: "(of which products/services)",
            rules: [{ required: true }],
            type: "number",
            number: {
              formatter: (value: any) =>
                `£ ${formatFees(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              parser: (value: any) => value.replace(/£\s?|(,*)/g, ""),
            },
          },
        ],
      },
      {
        name: "feesSummary",
        label: "Fees breakdown",
        tooltip:
          "Please provide further details of the products and services (if relevant).",
        rules: [],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "feesRoyalties",
        label: "Royalties share arrangements",
        tooltip:
          "Describe any royalty or revenue sharing arrangements and specify amounts in GBP.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "commercialIncentives",
        label: "Commercial incentives",
        tooltip:
          "Describe any other commercial incentives and specify amount in GBP.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "associatedFees",
        label: "Associated fees",
        tooltip:
          "Set out full list of any associated payments either paid or payable as part of the Transaction.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
    ],
    // tab 4, Assets and Partner Rights
    [
      {
        name: "partnerDesignations",
        label: "Partner designations",
        tooltip:
          "Describe any partner designations or rights or assets under the Transaction.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "exclusiveCategories",
        label: "Exclusive categories",
        tooltip:
          "Describe any exclusive categories of rights or assets under the Transaction.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "nonExclusiveCategories",
        label: "Non-exclusive categories",
        tooltip:
          "Describe any non-exclusive categories of rights or assets under the Transaction.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "territories",
        label: "Territories",
        tooltip:
          "Please select correct jurisdiction(s) from the drop-down menu.",
        rules: [{ required: true }],
        type: "select",
        select: {
          mode: "multiple",
          options: getTerritoryInputOptions(),
        },
      },
      {
        name: "teamRights",
        label: "Men’s Team rights only, Women’s team rights only, or both",
        tooltip:
          "Please confirm whether the rights granted by the Club to the counterparty relate solely to the men’s first team, solely to the women’s first team, or both.",
        rules: [{ required: true }],
        type: "select",
        select: {
          options: getTeamRightsOptions(),
        },
      },
      {
        name: "assetsProvided",
        label: "Assets provided by club",
        tooltip: "List any assets provided by the Club here.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "servicesProvided",
        label: "Services provided by club",
        tooltip: "List any services provided by the Club here.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "partnerRights",
        label: "Partner rights",
        tooltip:
          "Set out the partner’s rights here - short bullet points are fine.",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "licensingRights",
        label: "Licensing rights",
        tooltip: "List licencing rights here (if any).",
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "otherRights",
        label: "Other rights",
        tooltip:
          "Describe other rights arising under the Transaction documents (if any).",
        type: "textarea",
        maxLength: 4000,
      },
    ],
    // tab 5, Arm's Length Confirmation (threshold)
    [
      {
        name: "competitiveProcess",
        label: "Competitive process",
        tooltip:
          "Brief details of any tender/competitive process undertaken prior to the Transaction",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "competingBidders",
        label: "Competing bidders",
        tooltip:
          "Details of any competing bidders (e.g. number and type of bidders (anonymised if required))",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "competingBids",
        label: "Range of losing bids",
        tooltip:
          "Range of losing bids (anonymised if required, or as a percentage difference from the winning bid) ",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "transactionNature",
        label: "Nature of the transaction",
        tooltip:
          "Please describe the nature of the Transaction (e.g. is it a new deal/partner or is this a renewal? Is the Club entering a new brand sector, or is this an existing sector?)",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "comparableDealsValue",
        label: "Value of comparable deals",
        tooltip:
          "Please provide brief details of the approximate value of any comparable previous deal (if applicable)",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "sourceOfRelationship",
        label: "Source of relationship",
        tooltip:
          "Brief description of how the relationship was sourced, and how that worked in practice with any competitive process described above",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "complianceChecks",
        label: "Compliance checks",
        tooltip:
          "Confirmation of any compliance checks performed (e.g. anti-bribery, money laundering)",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
    ],
    // tab 6, Associated Party (associated)
    [
      {
        name: "fmvAssessment",
        label: "Associated party relationship",
        tooltip:
          "Description of the Fair Market Value Assessment between the Club and commercial partner",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "prospectingProcess",
        label: "Prospecting Process / Genesis of Transaction",
        tooltip:
          "Details of any prospecting process undertaken prior to the Transaction, or if no such process took place, an explanation as to the genesis of the Transaction (e.g. how the relationship was sourced). Please provide supporting documentation if available",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "agencyProcess",
        label: "Agency Process",
        tooltip:
          "Please confirm whether the Club engaged an agency in its process and if so, the terms of the agency agreement",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "competingOffers",
        label:
          "Competing offers, including: (i) identity of the offering party and jurisdiction(s) in which they operate; (ii) term of the offer; (iii) regional or global partnership; (iv) fee structure; and (v) inventory to be provided",
        tooltip:
          "Details of how many brands the Club (and/or the agent) approached, how many brands responded expressing interest, how many brands the Club (and/or the agent) met with, how many offers were received and, details of those offers as outlined at (i) to (v)",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "transactionNature",
        label: "Nature of the Transaction",
        tooltip:
          "Describe the nature of the Transaction (e.g. is it a new deal/partner or is this a renewal? Is the Club entering a new brand sector, or is this an existing sector?)",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "comparableDealsValue",
        label: "Value of comparable deals",
        tooltip:
          "Please provide brief details of the approximate value of any comparable previous deals (if applicable)",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "associatedPartyFmv",
        label: "Further information relevant to Fair Market Value",
        tooltip:
          "Any information, beyond that provided above, to demonstrate the Fair Market Value of the Transaction. Please provide supporting documentation if available",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
      {
        name: "aptDeclarationToFmv",
        label: "Associated party declaration as to Fair Market Value",
        tooltip:
          "Please provide a declaration by a director (or equivalent) of the Associated Party that they consider the Transaction to be at Fair Market Value (in accordance with Rule E.56) or provide the reference to the clause/schedule within the agreement containing the declaration",
        rules: [{ required: true }],
        type: "textarea",
        maxLength: 4000,
      },
    ],
    // tab 9, PL reports
    [
      {
        name: "furtherDetailsRequested",
        label: "Further details requested",
        tooltip:
          "Please select this checkbox if further details were requested to the Club",
        type: "radio",
        onChange: (v: any) => {
          const value = v.target.value;
          setTimeTakenForResponseDisabled(!value);
        },
        defaultValue: "no",
        options: [
          {
            value: true,
            label: "Yes",
          },
          {
            value: false,
            label: "No",
          },
        ],
      },
      {
        name: "timeTakenForResponse",
        label: "Time taken for response (days)",
        disabled: timeTakenForResponseDisabled,
        tooltip:
          "Please enter the amount of working days before a response was provided by the Club",
        type: "number",
      },
      {
        name: "furtherComments",
        label: "Further comments",
        type: "textarea",
        tooltip:
          "Please provide any further comments on the requests, including the date when further details were requested, and the date and content of any response",
        maxLength: 4000,
      },
      {
        name: "provisionalDeterminationDate",
        label: "Provisional determination date",
        tooltip:
          "Date on which provisional determination letter was sent to Club.",
        type: "date",
      },
      {
        name: "finalDeterminationDate",
        label: "Final determination date",
        disabled: timeTakenForResponseDisabled,
        tooltip: "Date on which final determination letter was sent to Club.",
        type: "date",
      },
      {
        name: "boardApprovalDate",
        label: "Date of board approval",
        type: "date",
        tooltip: "Date on which Board approval was confirmed to the Club.",
        maxLength: 4000,
      },
      {
        name: "transactionResolutionDate",
        label: "Transaction resolution date",
        type: "date",
        tooltip: "Date of transaction's final resolution.",
        maxLength: 4000,
      },
    ],
  ];

  return (
    <>
      {fieldsArr[index].map((field) => {
        if (field.dontDisplay)
          return <React.Fragment key="none"></React.Fragment>;

        if (field.type === "format_row")
          return (
            <SFieldRow key={field.name}>
              {field.entities?.map((field: TermFormField) =>
                renderField(field, canWrite, canCopy),
              )}
            </SFieldRow>
          );

        return renderField(field, canWrite, canCopy);
      })}
    </>
  );
};

const renderField = (
  field: TermFormField,
  canWrite: boolean,
  canCopy: boolean,
) => (
  <Form.Item
    name={field.name}
    key={field.name}
    tooltip={field.tooltip}
    style={field.style}
    label={field.label}
    rules={field.rules}
  >
    {fieldToJSX(field, canWrite, canCopy)}
  </Form.Item>
);

export default TermFormFields;
