import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import ShareTermModal from "../../components/modals/ShareTermModal";

import {
  Badge,
  Button,
  Card,
  Form,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Statistic,
  Typography,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  FilePdfOutlined,
  PauseOutlined,
  PlaySquareOutlined,
  RollbackOutlined,
  SaveOutlined,
  SendOutlined,
  ShareAltOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import PartnershipForm from "../../components/PartnershipForm";
import StateTag from "../../components/StateTag";
import TypeTag from "../../components/TypeTag";
import styled from "styled-components";
import ConvertTermModal from "../../components/modals/ConvertTermModal";
import {
  BespokeAttachments,
  Company,
  Term,
  TermForm as FormType,
} from "../../types";
import { TermStatus, TermType, UserRole } from "../../enums";
import {
  capitalizeFirstLetter,
  checkTermHistoricalState,
  formatFees,
} from "../../utils";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import TermService from "../../services/term.service";
import UserService from "../../services/user.service";
import Loading from "@ant-design/pro-card/lib/components/Loading";
import { permissionManager } from "../../services/permission_manager";
import { useGetAttachments } from "../../hooks/useGetAttachments";
import { useGetCompanies } from "../../hooks/useGetCompanies";

const { Paragraph } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const SPageHeader = styled(PageHeader)`
  border-bottom: 1px solid rgb(235, 237, 240);
  background: #fff;
  > * img {
    object-fit: contain;
  }
`;

const SPageContent = styled.div`
  margin: 24px 24px 0;
  padding: inherit;
`;

const draftMandatories = [
  "title",
  "description",
  "agreementDate",
  "contractingParty",
  "contractingPartyJurisdiction",
  "brand",
  "brandSector",
  "subjectMatter",
  "associatedParty",
  "armsLength",
  "summary",
  "commencementDate",
  "term",
  "renewalProvisions",
  "feesTotal",
  "feesCash",
  "feesInKind",
  "commercialIncentives",
  "feesRoyalties",
  "associatedFees",
  "partnerDesignations",
  "exclusiveCategories",
  "nonExclusiveCategories",
  "territories",
  "assetsProvided",
  "servicesProvided",
  "partnerRights",
  "teamRights",
];

const draftMandatoriesThreshold = [
  "competitiveProcess",
  "competingBidders",
  "competingBids",
  "transactionNature",
  "comparableDealsValue",
  "sourceOfRelationship",
  "strategicRationale",
  "complianceChecks",
];

const draftMandatoriesAssociated = [
  "fmvAssessment",
  "strategicRationaleClub",
  "strategicRationaleContractingParty",
  "associatedPartyFmv",
];

const FormPage = () => {
  const [canDeleteTerms, setCanDeleteTerms] = useState(false);

  const { companies } = useGetCompanies({
    onlyClubsAndDefault: true,
  });

  useEffect(() => {
    (async () => {
      const canDeleteTerms = await permissionManager.hasPermission(
        "cdbTerm",
        "Delete",
      );

      setCanDeleteTerms(canDeleteTerms);
    })();
  }, []);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { user, isFetchingUser } = useGetCurrentUser();

  const termService = useMemo(() => new TermService(), []);
  const userService = useMemo(() => new UserService(), []);

  const [company, setCompany] = useState({} as Company);
  const [term, setTerm] = useState({} as Term);
  const [number, setNumber] = useState("CDB0000000");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [state, setState] = useState("");
  const [type, setType] = useState("");
  const [descriptionCreatedDate, setDescriptionCreatedDate] = useState("");
  const [descriptionSubmissionDate, setDescriptionSubmissionDate] =
    useState("");
  const [descriptionTotalFees, setDescriptionTotalFees] = useState<string>();
  const [descriptionClub, setDescriptionClub] = useState();
  const [descriptionSubmittedBy, setDescriptionSubmittedBy] = useState("");
  const [formLoading, setFormLoading] = useState(true);
  const [bespokeAttachments, setBespokeAttachments] =
    useState<BespokeAttachments>({
      unredacted_agreement: [],
      redacted_agreement: [],
      pl_reports: [],
      apt_declarations: [],
    });
  const [convertModalVisible, setConvertModalVisible] = useState(false);
  const [historicalTermInfoModalVisible, setHistoricalTermInfoModalVisible] =
    useState<{
      value: boolean;
      isHistoricalDate?: boolean;
      notInPLDuringAgreementDate?: boolean;
    }>({
      value: false,
      isHistoricalDate: false,
      notInPLDuringAgreementDate: false,
    });
  const [isExportingPdf, setIsExportingPdf] = useState(false);
  const [sharingModalVisible, setSharingModalVisible] = useState(false);

  const params = useParams();

  const id = params.id;
  const { attachmentsCount, refetchAttachments } = useGetAttachments(id);
  const isThreshold = type === TermType.threshold;
  const isAssociatedParty = type === TermType.associated;

  const isUserAdmin = user.role === UserRole.admin;
  const isUserLegal = user.role === UserRole.legal;
  const isUserClub = user.role === UserRole.club;
  const isUserExternal = user.role === UserRole.external;

  const isAdminOrLegal = isUserAdmin || isUserLegal;
  const isDraftState = state === TermStatus.draft;
  const isClubAndDraft = isUserClub && isDraftState;

  const canWrite =
    !isUserExternal ||
    (!isUserExternal && isAdminOrLegal) ||
    (state !== TermStatus.historical &&
      state !== TermStatus.submitted &&
      ((type === TermType.databank && (isAdminOrLegal || isClubAndDraft)) ||
        (type === TermType.threshold &&
          (isAdminOrLegal ||
            (isUserLegal && !isDraftState) ||
            isClubAndDraft)) ||
        (type === TermType.associated && (isAdminOrLegal || isClubAndDraft))));

  const canCopy = !isUserExternal;

  const routes = {
    items: [
      { title: "Transactions", path: "" },
      { title: number, path: "" },
    ],
  };

  //get form data
  const fetchFormData = async () => {
    setFormLoading(true);
    const hide = message.loading("Loading..");

    if (id) {
      try {
        const term = await termService.getTerm(id, "");

        setCompany(term.company);
        setTerm(term);

        let formData = {
          ...term,
          agreementDate: dayjs(term.agreementDate),
        };

        formData.agreementDate =
          term.agreementDate && dayjs(term.agreementDate);
        formData.commencementDate =
          term.commencementDate && dayjs(term.commencementDate);
        formData.expiryDate = term.expiryDate && dayjs(term.expiryDate);
        formData.submissionDate =
          term.submissionDate && dayjs(term.submissionDate);
        formData.provisionalDeterminationDate =
          term.provisionalDeterminationDate &&
          dayjs(term.provisionalDeterminationDate);
        formData.finalDeterminationDate =
          term.finalDeterminationDate && dayjs(term.finalDeterminationDate);
        formData.boardApprovalDate =
          term.boardApprovalDate && dayjs(term.boardApprovalDate);
        formData.transactionResolutionDate =
          term.transactionResolutionDate &&
          dayjs(term.transactionResolutionDate);

        if (term.submissionDate) {
          setDescriptionCreatedDate(
            term.submissionDate &&
              dayjs(term.submissionDate).format("D MMM, YYYY"),
          );
        }

        if (term.submittedToPlDate) {
          setDescriptionSubmissionDate(
            term.submittedToPlDate &&
              dayjs(term.submittedToPlDate).format("D MMM, YYYY"),
          );
        }

        if (term.feesTotal) {
          setDescriptionTotalFees(formatFees(term.feesTotal));
        }

        if (term.createdBy) {
          const createdBy = await userService.getUser(term.createdBy);
          setDescriptionSubmittedBy(createdBy.email);
        }

        if (term.company.name) {
          setDescriptionClub(term.company.name);
        }

        form.setFieldsValue(formData);

        if (term.number) setNumber(`CDB${term.number}`);
        if (term.title) setTitle(term.title);
        if (term.description) setDescription(term.description);
        if (term.state) setState(term.state);
        if (term.type) setType(term.type);

        setFormLoading(false);
        hide();
      } catch (err: any) {
        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (err.response.status === 401) {
            navigate("/403");
            return;
          }
        }
        navigate("/404");
      }
    }
  };

  //init form data
  useEffect(() => {
    if (id) {
      fetchFormData();
    }
  }, [id]);

  //save data
  const saveData = async (data: any) => {
    setFormLoading(true);

    if (!data.furtherDetailsRequested) {
      data["timeTakenForResponse"] = null;
    }

    const hide = message.loading("Saving...", 1.0);

    try {
      const updateTerm = await termService.updateTerm({ ...data, id });

      if (updateTerm) {
        hide();
        message.success("Saved", 2.5);
      }
    } catch (err) {
      hide();
      console.log(err);
      message.error(`Error in updating transaction`, 2.5);
    } finally {
      setFormLoading(false);
      fetchFormData();
    }
  };

  const onFormFinish = (fieldValues: any) => {
    const values = {
      ...fieldValues,
    };
    saveData(values);
  };

  const updateState = (targetState: any) => {
    saveData({
      state: targetState,
    });
  };

  const save = () => {
    saveData(form.getFieldsValue());
  };

  const deleteRecord = async () => {
    const hide = message.loading("Deleting record..", 1.0);

    try {
      const deleteReq = await termService.deleteTerm({
        id,
        userRole: user.role,
      });

      if (deleteReq) {
        hide();
        setFormLoading(false);
        message.success("Transaction deleted", 2.5);
        navigate("/transaction");
      }
    } catch (err: any) {
      hide();
      console.log(err.message);
      message.error("Error in deleting transaction");
    }
  };

  const onPreSubmit = () => {
    let i;
    let output = form.getFieldsValue() as FormType;
    let formComplete = true;

    //mandatory fields for draft
    for (i = 0; i < draftMandatories.length; i++) {
      const value = form.getFieldValue(draftMandatories[i]);

      if (value !== 0 && (!value || value.length === 0)) {
        console.log(
          "setting complete to false because " +
            draftMandatories[i] +
            " = " +
            value,
        );
        formComplete = false;
      }
    }

    //additional mandatories for threshold
    if (isThreshold) {
      //field mandatories
      for (i = 0; i < draftMandatoriesThreshold.length; i++) {
        const value = form.getFieldValue(draftMandatoriesThreshold[i]);
        if (value !== 0 && (!value || value.length === 0)) {
          formComplete = false;
        }
      }

      if (attachmentsCount === 0) {
        message.error(
          "At least one attachment is required to submit a Threshold transaction.",
          3.0,
        );
        return;
      }
    }

    //additional mandatories for threshold
    if (isAssociatedParty) {
      // mandatory fields
      for (i = 0; i < draftMandatoriesAssociated.length; i++) {
        const value = form.getFieldValue(draftMandatoriesAssociated[i]);
        if (value !== 0 && (!value || value.length === 0)) {
          formComplete = false;
        }
      }

      if (
        bespokeAttachments.redacted_agreement.length === 0 ||
        bespokeAttachments.unredacted_agreement.length === 0
      ) {
        message.error(
          "Redacted and unredacted agreement attachments are required to submit an Associated Party transaction.",
          3.0,
        );
        return;
      }
    }

    if (!formComplete) {
      message.error("Form incomplete.", 3.0);
    } else {
      form.validateFields().then(() => {
        // check if the agreement date of the term categorises it as historical
        const getCompany = () => {
          return companies.find((c) => c.id === term.company.id);
        };

        const { isHistoricalDate, notInPLDuringAgreementDate } =
          checkTermHistoricalState({
            company: getCompany(),
            agreementDate: new Date(output.agreementDate),
          });

        if (isHistoricalDate || notInPLDuringAgreementDate) {
          setHistoricalTermInfoModalVisible({
            value: true,
            isHistoricalDate,
            notInPLDuringAgreementDate,
          });
        } else {
          onSaveAndSubmit();
        }
      });
    }
  };

  const onSaveAndSubmit = (state?: TermStatus) => {
    let output = form.getFieldsValue() as FormType;

    if (state) {
      output.state = state;
    } else {
      output.state = TermStatus.pending;
    }

    saveData(output);
  };

  const onExportPdf = async () => {
    setIsExportingPdf(true);
    try {
      const payload = {
        id: number,
        template: "pl_commercial_data_bank",
        isPublic: true,
        params: {
          number: number,
          title: form.getFieldValue("title"),
          type: capitalizeFirstLetter(type),
          description: form.getFieldValue("description") || "-",
          agreementDate:
            dayjs(form.getFieldValue("agreementDate"))?.format("D MMM, YYYY") ||
            "-",
          submissionDate: descriptionCreatedDate || "-",
          submittedBy: descriptionSubmittedBy || "-",
          commencementDate:
            dayjs(form.getFieldValue("commencementDate"))?.format(
              "D MMM, YYYY",
            ) || "-",
          expiryDate:
            dayjs(form.getFieldValue("expiryDate"))?.format("D MMM, YYYY") ||
            "-",
          contractingPartyJurisdiction:
            form.getFieldValue("contractingPartyJurisdiction") || "-",
          brand: form.getFieldValue("brand") || "-",
          territories: form.getFieldValue("territories"),
          parentCompany: form.getFieldValue("parentCompany") || "-",
          brandSector: form.getFieldValue("brandSector") || "-",
          subjectMatter: form.getFieldValue("subjectMatter") || "-",
          associatedParty:
            form.getFieldValue("associatedParty")?.toUpperCase() || "-",
          armsLength: form.getFieldValue("armsLength")?.toUpperCase() || "-",
          summary: form.getFieldValue("summary") || "-",
          term: form.getFieldValue("term") || "-",
          renewalProvisions: form.getFieldValue("renewalProvisions") || "-",
          strategicRationale: form.getFieldValue("strategicRationale") || "-",
          feesCash: form.getFieldValue("feesCash") || "-",
          feesInKind: form.getFieldValue("feesInKind") || "-",
          feesTotal: form.getFieldValue("feesTotal") || "-",
          feesSummary: form.getFieldValue(descriptionTotalFees) || "-",
          feesRoyalties: form.getFieldValue("feesRoyalties") || "-",
          commercialIncentives:
            form.getFieldValue("commercialIncentives") || "-",
          associatedFees: form.getFieldValue("associatedFees") || "-",
          partnerDesignations: form.getFieldValue("partnerDesignations") || "-",
          exclusiveCategories: form.getFieldValue("exclusiveCategories") || "-",
          nonExclusiveCategories:
            form.getFieldValue("nonExclusiveCategories") || "-",
          assetsProvided: form.getFieldValue("assetsProvided") || "-",
          servicesProvided: form.getFieldValue("servicesProvided") || "-",
          partnerRights: form.getFieldValue("partnerRights") || "-",
          licensingRights: form.getFieldValue("licensingRights") || "-",
          otherRights: form.getFieldValue("otherRights") || "-",
          competitiveProcess: form.getFieldValue("competitiveProcess") || "-",
          competingBidders: form.getFieldValue("competingBidders") || "-",
          competingBids: form.getFieldValue("competingBids") || "-",
          transactionNature: form.getFieldValue("transactionNature") || "-",
          comparableDealsValue:
            form.getFieldValue("comparableDealsValue") || "-",
          sourceOfRelationship:
            form.getFieldValue("sourceOfRelationship") || "-",
          complianceChecks: form.getFieldValue("complianceChecks") || "-",
          teamRights: form.getFieldValue("teamRights") || "-",
          strategicRationaleClub:
            form.getFieldValue("strategicRationaleClub") || "-",
          strategicRationaleContractingParty:
            form.getFieldValue("strategicRationaleContractingParty") || "-",
          fmvAssessment: form.getFieldValue("fmvAssessment") || "-",
          prospectingProcess: form.getFieldValue("prospectingProcess") || "-",
          agencyProcess: form.getFieldValue("agencyProcess") || "-",
          competingOffers: form.getFieldValue("competingOffers") || "-",
          associatedPartyFmv: form.getFieldValue("associatedPartyFmv") || "-",
          aptDeclarationToFmv: form.getFieldValue("aptDeclarationToFmv") || "-",
        },
      };

      console.log("payload", payload);
      const response = await termService.exportTermToPdf(payload);

      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      const fileURL = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = `${number} Transaction Report.pdf`;
      link.click();
    } catch (err) {
      console.log(err);
    }
    setIsExportingPdf(false);
  };

  if (isFetchingUser || !user.email) {
    return <Loading />;
  }

  return (
    <>
      {convertModalVisible && (
        <ConvertTermModal
          termId={id}
          termNumber={number}
          termType={type}
          isModalVisible={convertModalVisible}
          setIsModalVisible={setConvertModalVisible}
        />
      )}
      {sharingModalVisible && (
        <ShareTermModal
          term={term}
          termNumber={number}
          termType={type}
          isModalVisible={sharingModalVisible}
          setIsModalVisible={() => {
            setSharingModalVisible(!sharingModalVisible);
            fetchFormData();
          }}
        />
      )}
      <SPageHeader
        title={title}
        breadcrumb={routes}
        tags={[
          state && <StateTag key={0} state={TermStatus[state as TermStatus]} />,
          type && (
            <TypeTag
              key={1}
              type={TermType[type as TermType]}
              convertedOn={term.convertedOn}
            />
          ),
        ]}
        avatar={{ src: `${company?.logo}`, size: "large" }}
        extra={[
          canDeleteTerms && (
            <Popconfirm
              title="Are you sure you would like to delete this record?"
              key="delete-record"
              icon={<DeleteOutlined />}
              onConfirm={() => deleteRecord()}
            >
              <Button
                danger={true}
                loading={formLoading}
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          ),
          isUserAdmin && (
            <Button
              key="open-convert-modal"
              loading={formLoading}
              onClick={() => setConvertModalVisible(true)}
              icon={<SwapOutlined />}
            />
          ),
          isUserAdmin && (
            <Badge
              key="share-term-badge"
              count={term.sharedWith?.length}
              style={{ marginRight: "5px" }}
              color={"#c800c0"}
            >
              <Button
                key="share-term"
                loading={formLoading}
                onClick={() => setSharingModalVisible(true)}
                icon={<ShareAltOutlined />}
                title={`Current shared with ${term.sharedWith?.length} users`}
              >
                Share
              </Button>
            </Badge>
          ),
          state !== "draft" && canWrite && (isUserAdmin || isUserLegal) && (
            <Popconfirm
              title="Send this transaction back to draft?"
              key="back-to-draft"
              icon={<CloseCircleOutlined />}
              onConfirm={() => updateState(TermStatus.draft)}
              style={{ color: "red" }}
            >
              <Button loading={formLoading} icon={<RollbackOutlined />}>
                Draft
              </Button>
            </Popconfirm>
          ),
          // todo restate button logic, they no longer want it but let's keep it just in case
          // type === TermType.associated && state !== TermStatus.restated && (
          //   <Popconfirm
          //     title="Set this transaction state to Restated?"
          //     key="back-to-draft"
          //     icon={<RetweetOutlined />}
          //     onConfirm={() => updateState(TermStatus.restated)}
          //     style={{ color: "red" }}
          //   >
          //     <Button loading={formLoading} icon={<RollbackOutlined />}>
          //       Restate
          //     </Button>
          //   </Popconfirm>
          // ),
          (state === TermStatus.pending ||
            state === TermStatus.provisionally_determined) &&
            canWrite &&
            (isUserAdmin || isUserLegal) && (
              <Popconfirm
                title="Confirm reject"
                key="confirm-reject"
                icon={<CloseCircleOutlined />}
                onConfirm={() => updateState(TermStatus.rejected)}
                style={{ color: "red" }}
              >
                <Button loading={formLoading} icon={<CloseOutlined />}>
                  Reject
                </Button>
              </Popconfirm>
            ),
          state === TermStatus.pending &&
            canWrite &&
            (isUserAdmin || isUserLegal) && (
              <Popconfirm
                title="Confirm Provisionally Determined"
                key="provisionally_determined"
                icon={<PauseOutlined />}
                onConfirm={() =>
                  updateState(TermStatus.provisionally_determined)
                }
                style={{ color: "red" }}
              >
                <Button loading={formLoading} icon={<PauseOutlined />}>
                  Provisionally Determined
                </Button>
              </Popconfirm>
            ),
          state === TermStatus.provisionally_determined &&
            canWrite &&
            (isUserAdmin || isUserLegal) && (
              <Popconfirm
                title="Confirm Revert to Pending"
                key="revert_to_pending"
                icon={<PlaySquareOutlined />}
                onConfirm={() => updateState(TermStatus.pending)}
                style={{ color: "red" }}
              >
                <Button loading={formLoading} icon={<PlaySquareOutlined />}>
                  Revert to Pending
                </Button>
              </Popconfirm>
            ),
          (state === TermStatus.pending ||
            state === TermStatus.provisionally_determined) &&
            canWrite &&
            (isUserAdmin || isUserLegal) && (
              <Popconfirm
                title="Confirm approve"
                key="confirm-approve"
                icon={<CheckCircleOutlined />}
                onConfirm={() => updateState(TermStatus.approved)}
                style={{ color: "green" }}
              >
                <Button loading={formLoading} icon={<CheckOutlined />}>
                  Approve
                </Button>
              </Popconfirm>
            ),
          !isUserExternal && (
            <Button
              key="export-pdf"
              loading={isExportingPdf}
              onClick={onExportPdf}
              icon={<FilePdfOutlined />}
            >
              Export PDF
            </Button>
          ),
          canWrite && (
            <Button
              loading={formLoading}
              type="primary"
              disabled={!canWrite}
              key="save"
              onClick={() => save()}
              icon={<SaveOutlined />}
            >
              Save
            </Button>
          ),
          canWrite && state === "draft" && (
            <Popconfirm
              title="Submit for review?"
              key="submit"
              icon={<CheckCircleOutlined />}
              onConfirm={onPreSubmit}
            >
              <Button loading={formLoading} icon={<SendOutlined />}>
                Submit
              </Button>
            </Popconfirm>
          ),
        ]}
      >
        <Row>
          <div style={{ flex: 1 }}>
            <Paragraph>{description}</Paragraph>
          </div>

          <Space size="large">
            {descriptionCreatedDate && (
              <Statistic
                title="Created"
                value={descriptionCreatedDate}
                valueStyle={{ fontSize: "large" }}
              />
            )}

            {descriptionSubmissionDate && (
              <Statistic
                title="Submitted"
                value={descriptionSubmissionDate}
                valueStyle={{ fontSize: "large" }}
              />
            )}

            {descriptionTotalFees && (
              <Statistic
                title="Total fees"
                prefix="£"
                value={descriptionTotalFees}
                valueStyle={{ fontSize: "large" }}
              />
            )}

            {descriptionClub && (
              <Statistic
                title="Club"
                value={descriptionClub}
                valueStyle={{ fontSize: "large" }}
              />
            )}

            {isAdminOrLegal && (
              <Statistic
                title="Created by"
                value={descriptionSubmittedBy}
                valueStyle={{ fontSize: "large" }}
              />
            )}
          </Space>
        </Row>
      </SPageHeader>
      <SPageContent>
        <Card>
          <PartnershipForm
            {...formItemLayout}
            form={form}
            emitBespokeAttachments={setBespokeAttachments}
            onFinish={onFormFinish}
            newRecord={false}
            canWrite={canWrite}
            canCopy={canCopy}
            userRole={user.role}
            isThreshold={isThreshold}
            isAssociatedParty={isAssociatedParty}
            scrollToFirstError
            company={company}
            term={term}
            onAttachmentsChanged={() => refetchAttachments()}
          />
        </Card>
      </SPageContent>
      <Modal
        title="Historical Transaction"
        width={600}
        open={historicalTermInfoModalVisible.value}
        onOk={() => onSaveAndSubmit(TermStatus.historical)}
        onCancel={() =>
          setHistoricalTermInfoModalVisible({
            value: false,
            isHistoricalDate: false,
            notInPLDuringAgreementDate: false,
          })
        }
        closable={false}
        footer={[
          <Button
            key="back"
            onClick={() =>
              setHistoricalTermInfoModalVisible({
                value: false,
                isHistoricalDate: false,
                notInPLDuringAgreementDate: false,
              })
            }
          >
            Cancel
          </Button>,

          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setHistoricalTermInfoModalVisible({ value: false });
              onSaveAndSubmit(TermStatus.historical);
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <Paragraph>
          {historicalTermInfoModalVisible.isHistoricalDate
            ? "As the agreement was made between the 1st of January 2016 and the 14th of December 2021"
            : "As the club was not in Premier League at the time the agreement was made"}
          , no approval is required for this transaction and it will be logged
          as <strong>historical</strong>. <br />
        </Paragraph>
      </Modal>
    </>
  );
};

export default FormPage;
