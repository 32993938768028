import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import Highlighter from "react-highlight-words";

import { Button, Card, Dropdown, Pagination, Select, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { PageHeader } from "@ant-design/pro-components";
import {
  DownOutlined,
  ExportOutlined,
  InsertRowAboveOutlined,
  InsertRowBelowOutlined,
  PlusOutlined,
  SyncOutlined,
  UploadOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import StateTag from "../../components/StateTag";

import CompanyTag from "../../components/CompanyTag";
import TableToolbar from "../../components/TableToolbar";
import TypeTag from "../../components/TypeTag";
import DraftTransactionModal from "../../components/modals/DraftTransactionModal";
import ImportDataModal from "../../components/modals/ImportDataModal";

import styled from "styled-components";
import {
  Company,
  TermListFilter,
  TermListFilterEnum,
  Term,
  FilterField,
  FullTermListFilterForm,
  FullTermListFilterEnum,
} from "../../types";
import { TablePaginationConfig } from "antd/es/table/interface";
import { TermStatus, TermType, UserRole } from "../../enums";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import { useGetCsvData } from "../../hooks/useGetCsvData";
import { permissionManager } from "../../services/permission_manager";
import { useNavigate } from "react-router-dom";
import TermService from "../../services/term.service";
import { FilterCard } from "../../components/shared/FilterCard";
import {
  capitalizeFirstLetter,
  formatFees,
  getDataForCsvExport,
  getLabelForTransactionType,
} from "../../utils";
import BrandSectorOptions from "../../components/enums/BrandSectorOptions";
import getSubjectMatterOptions from "../../components/enums/getSubjectMatterOptions";
import getTerritoryInputOptions from "../../components/enums/getTerritoryInputOptions";
import getClubInputOptions from "../../components/enums/getClubInputOptions";
import { useGetCompanies } from "../../hooks/useGetCompanies";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const { Option } = Select;

const routes = {
  items: [{ title: "Transactions" }, { title: "List" }],
};

const SPageHeader = styled(PageHeader)`
  border-bottom: 1px solid rgb(235, 237, 240);
  background: #fff;
`;

const SPageContent = styled.div`
  margin: 24px 24px 0;
  padding: inherit;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TransactionList = () => {
  const [canCreateTerms, setCanCreateTerms] = useState(false);

  useEffect(() => {
    (async () => {
      const canCreateTerms = await permissionManager.hasPermission(
        "cdbTerm",
        "Create",
      );

      setCanCreateTerms(canCreateTerms);
    })();
  }, [permissionManager]);

  const termService = new TermService();
  const { user } = useGetCurrentUser();
  const { companies } = useGetCompanies({ onlyClubsAndDefault: true });

  const [queryParams, setQueryParams] = useState("");
  const [csvQueryParams, setCsvQueryParams] = useState("");
  const [terms, setTerms] = useState<Term[]>();
  const [termsCount, setTermsCount] = useState<number>();

  const fetchTerms = async () => {
    setLoading(true);
    const terms = await termService.getTerms(queryParams);

    if (terms && terms.data && terms.count !== undefined) {
      setTerms(terms.data);
      setTermsCount(terms.count);
      buildTableData();
    }
    setLoading(false);
  };
  const { csvData } = useGetCsvData(`${csvQueryParams}`, "terms");

  const [data, setData] = useState<Term[]>([]);
  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: localStorage.transactionPage ?? 1,
    pageSize: localStorage.transactionPageSize ?? 10,
    total: termsCount || 0,
  });

  const [pageCount, setPageCount] = useState({
    total: 0,
    startWindow: 0,
    endWindow: 0,
  });

  const getCachedSorter = () => {
    const cachedSorterString = localStorage.getItem("transactionPageSorter");
    if (cachedSorterString) {
      return JSON.parse(cachedSorterString);
    }

    return {
      field: undefined,
      order: undefined,
    };
  };

  const [sorter, setSorter] = useState(getCachedSorter());

  const getCachedFilter = () => {
    const odataCachedFilterString = localStorage.getItem(
      "odataTermListFilterString",
    );
    const odataFilterObj = localStorage.getItem("odataTermListFilterObj");
    const customQueryString = localStorage.getItem("customQueryString");

    return { odataCachedFilterString, odataFilterObj, customQueryString };
  };

  const [filter, setFilter] = useState<TermListFilter>(
    JSON.parse(getCachedFilter().odataFilterObj),
  );

  const [odataFilterString, setOdataFilterString] = useState<string>(
    getCachedFilter().odataCachedFilterString,
  );

  const [customQueryString, setCustomQueryString] = useState<string>(
    getCachedFilter().customQueryString,
  );

  const [searchedText, setSearchedText] = useState("");
  const [searchLogic, setSearchLogic] = useState<string>("and");
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [termModalVisible, setTermModalVisible] = useState(false);

  const [newDraftType, setNewDraftType] = useState<TermType>();

  const navigate = useNavigate();

  const isUserAdmin = user.role === UserRole.admin;
  const isUserClub = user.role === UserRole.club;

  const generalFilterFields: FilterField[] = [
    {
      name: "title",
      label: "Title",
      type: "text",
    },
    {
      name: "number",
      label: "Tracking ID",
      type: "number",
      placeholder: "1000***",
      number: {
        formatter: (value: number) =>
          `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        parser: (value: string) => value.replace(/£\s?|(,*)/g, ""),
      },
    },
    {
      name: "state",
      label: "Status",
      type: "select",
      select: {
        mode: "multiple",
        options: (
          <>
            {Object.values(TermStatus).map((ts) => {
              return (
                <Option value={ts}>
                  {capitalizeFirstLetter(ts.replace("_", " "))}
                </Option>
              );
            })}
          </>
        ),
      },
    },
    {
      name: "type",
      label: "Type",
      type: "select",
      select: {
        mode: "multiple",
        options: (
          <>
            {Object.keys(TermType).map((transactionType, index) => {
              return (
                <Option key={index} value={transactionType}>
                  {getLabelForTransactionType(transactionType as TermType)}
                </Option>
              );
            })}
          </>
        ),
      },
    },
    {
      name: "submissionDate",
      label: "Created",
      type: "daterange",
    },
    {
      name: "submittedToPlDate",
      label: "Submitted",
      type: "daterange",
    },
    {
      name: "agreementDate",
      label: "Execution date",
      type: "daterange",
    },
    {
      name: "commencementDate",
      label: "Commencement date",
      type: "daterange",
    },
    {
      name: "brand",
      label: "Brand",
      type: "text",
    },
    {
      name: "brandSector",
      label: "Brand sector",
      type: "cascader",
      cascader: {
        mode: "multiple",
        options: BrandSectorOptions,
      },
    },
    {
      name: "subjectMatter",
      label: "Subject matter",
      type: "select",
      select: {
        mode: "multiple",
        options: getSubjectMatterOptions(),
      },
    },
    {
      name: "territories",
      label: "Territories",
      type: "select",
      select: {
        mode: "multiple",
        options: getTerritoryInputOptions(),
      },
    },
    {
      name: "associatedParty",
      label: "Assoc. party",
      type: "select",
      select: {
        options: (
          <>
            <Option value="a">Yes</Option>
            <Option value="b">No</Option>
          </>
        ),
      },
    },
    {
      name: "armsLength",
      label: "Arm's length",
      type: "select",
      select: {
        options: (
          <>
            <Option value="a">Yes</Option>
            <Option value="b">No</Option>
          </>
        ),
      },
    },
  ];

  const adminFilterFields: FilterField[] = [
    {
      name: "companyId",
      label: "Club",
      type: "select",
      select: {
        filterOptionsBy: "display",
        mode: "multiple",
        options: getClubInputOptions(companies),
      },
    },
  ];

  const getFilterFields = () => {
    if (isUserAdmin) {
      return [...adminFilterFields, ...generalFilterFields];
    }

    return generalFilterFields;
  };

  const columns: ColumnType<any>[] = [
    {
      title: "Tracking ID",
      dataIndex: "number",
      key: "number",
      width: 140,
      render: (text: any, record: Term) => (
        <a href={`/transaction/${encodeURIComponent(record.id)}`}>CDB{text}</a>
      ),
      sorter: true,
      sortOrder: sorter.columnKey === "number" ? sorter.order : null,
      fixed: "left",
    },
    {
      title: "Type",
      key: "type",
      sortOrder: sorter.columnKey === "type" ? sorter.order : null,
      dataIndex: "type",
      render: (type: any, record: Term) => (
        <TypeTag type={type} convertedOn={record.convertedOn} />
      ),
      width: 140,
      filteredValue: (filter && filter["type"]) || null,
      sorter: true,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sortOrder: sorter.columnKey === "title" ? sorter.order : null,
      width: 200,
      filteredValue: [filter && filter["title"]] || null,
      render: (text: any) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
      sorter: true,
    },
    {
      title: "Club",
      dataIndex: "company",
      key: "company",
      width: 175,
      render: (company: Company) => <CompanyTag company={company} />,
    },
    {
      title: "Contracting party",
      dataIndex: "contractingParty",
      key: "contractingParty",
      sortOrder: sorter.columnKey === "contractingParty" ? sorter.order : null,
      width: 200,
      render: (text: any) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
      sorter: true,
    },
    {
      title: "Status",
      key: "state",
      sortOrder: sorter.columnKey === "state" ? sorter.order : null,
      dataIndex: "state",
      render: (state: any) => <StateTag state={state} />,
      sorter: true,
      width: 200,
    },
    {
      title: "Fees (£)",
      dataIndex: "feesTotal",
      key: "feesTotal",
      sortOrder: sorter.columnKey === "feesTotal" ? sorter.order : null,
      width: 150,
      sorter: true,
      render: (feesTotal: any) =>
        feesTotal &&
        `£${formatFees(feesTotal)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      title: "Date of agreement",
      dataIndex: "agreementDate",
      key: "agreementDate",
      sortOrder: sorter.columnKey === "agreementDate" ? sorter.order : null,
      width: 200,
      sorter: true,
      render: (agreementDate: any) =>
        agreementDate ? dayjs(agreementDate).format("Do MMM, YYYY") : "",
    },
    {
      title: "Created date",
      dataIndex: "submissionDate",
      key: "submissionDate",
      sortOrder: sorter.columnKey === "submissionDate" ? sorter.order : null,
      width: 200,
      sorter: true,
      render: (submissionDate: any) =>
        submissionDate ? dayjs(submissionDate).format("Do MMM, YYYY") : "",
    },
    {
      title: "Submitted date",
      dataIndex: "submittedToPlDate",
      key: "submittedToPlDate",
      sortOrder: sorter.columnKey === "submittedToPlDate" ? sorter.order : null,
      width: 200,
      sorter: true,
      render: (submittedToPlDate: any) =>
        submittedToPlDate
          ? dayjs(submittedToPlDate).format("Do MMM, YYYY")
          : "",
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (text: any, record: Term) => (
        <Button href={`/transaction/${encodeURIComponent(record.id)}`}>
          View
        </Button>
      ),
      fixed: "right",
    },
  ];

  useEffect(() => {
    if (
      pagination.pageSize &&
      pagination.current
      // sorter.field ||
      // odataFilterString ||
      // customQueryString
    ) {
      buildODataQuery();

      setPageCount({
        total: pagination.total,
        startWindow: Math.min(
          termsCount,
          ((pagination.current || 1) - 1) * (pagination.pageSize || 10) + 1,
        ),
        endWindow: Math.min(
          pagination.total,
          pagination.current * (pagination.pageSize || 10),
        ),
      });
    }
  }, [
    pagination.pageSize,
    pagination.current,
    pagination.total,
    sorter?.field,
    sorter?.order,
    odataFilterString,
    customQueryString,
  ]);

  const buildODataQuery = () => {
    const queryParamsUnited = [];

    // for csv we fetch the same records, but without pagination
    const csvQueryParams = [];

    // Pagination
    // OData uses $skip and $top for pagination
    queryParamsUnited.push(
      `$skip=${(pagination.current - 1) * pagination.pageSize}`,
    );
    queryParamsUnited.push(`$top=${pagination.pageSize}`);

    // Sorting
    if (sorter && sorter.field) {
      // Mapping Ant Design's sorting to OData
      const order = sorter.order === "descend" ? "desc" : "asc";
      queryParamsUnited.push(`$orderby=${sorter.field} ${order}`);
      csvQueryParams.push(`$orderby=${sorter.field} ${order}`);
    }

    // Custom query params (for filtering in the string arrays in the DB)
    if (!!customQueryString) {
      queryParamsUnited.push(`${customQueryString}`);
      csvQueryParams.push(`${customQueryString}`);
    }

    // Filtering
    if (!!odataFilterString) {
      queryParamsUnited.push(`$filter=${odataFilterString}`);
      csvQueryParams.push(`$filter=${odataFilterString}`);
    }

    setQueryParams(`?${queryParamsUnited.join("&")}`);
    setCsvQueryParams(`?${csvQueryParams.join("&")}`);
  };

  useEffect(() => {
    if (queryParams) {
      fetchTerms();
    }
  }, [queryParams]);

  const buildTableData = () => {
    if (terms) {
      let outp = [];
      for (let i = 0; i < terms?.length; i++) {
        let item = {
          key: i,
          ...terms[i],
        };
        outp.push(item);
      }

      setData(outp);
      setLoading(false);
    }
  };

  //create new transaction process
  const showTermModal = () => {
    setTermModalVisible(true);
  };

  const handleCreateNewMenuClick = (event: { key: TermType }) => {
    if (event.key === TermType.databank) {
      setNewDraftType(TermType.databank);
    } else if (event.key === TermType.threshold) {
      setNewDraftType(TermType.threshold);
    } else if (event.key === TermType.associated) {
      setNewDraftType(TermType.associated);
    }
    showTermModal();
  };

  // export CSV process
  const headers = [
    { label: "Tracking ID", key: "trackingId" },
    { label: "Organisation", key: "organisation" },
    { label: "Transaction type", key: "transactionType" },
    { label: "Title", key: "title" },
    { label: "Contracting party", key: "contractingParty" },
    { label: "Status", key: "status" },
    { label: "Fees (in kind)", key: "feesInKind" },
    { label: "Fees (cash)", key: "feesCash" },
    { label: "Fees (total)", key: "feesTotal" },
    { label: "Date of agreement", key: "dateOfAgreement" },
    { label: "Created date", key: "createdDate" },
    { label: "Submitted date", key: "submittedDate" },
    { label: "Conversion date", key: "convertedOn" },
    { label: "Further details requested", key: "furtherDetailsRequested" },
  ];

  //import data process
  const showImportModal = () => {
    setImportModalVisible(true);
  };

  //update filter
  const updateFilterString = (newFilterObj: FullTermListFilterForm) => {
    // only the filter fields with actual values
    const filterBy = Object.entries(newFilterObj).reduce(
      (acc, [key, value]) => {
        if (value) {
          // check if the value is truthy & thus removing empty filters
          // @ts-ignore
          acc[key] = value; // add the key-value pair to the accumulator object
        }
        return acc;
      },
      {},
    ) as TermListFilter;

    let odataFilter: string[] = [];
    let customQuery: string[] = [];
    Object.keys(filterBy).forEach((field) => {
      if (field === TermListFilterEnum.text && !!filterBy.text!.trim()) {
        let input = filterBy.text!.trim();

        if (input) {
          let searchDefs = [
            FullTermListFilterEnum.title,
            FullTermListFilterEnum.description,
            FullTermListFilterEnum.contractingParty,
            FullTermListFilterEnum.summary,
            FullTermListFilterEnum.competingBidders,
            FullTermListFilterEnum.partnerDesignations,
            FullTermListFilterEnum.exclusiveCategories,
            FullTermListFilterEnum.nonExclusiveCategories,
          ];

          let searchValues: string[];

          if (input.match(/"/g)) {
            searchValues = input.split(/ (?=([^\"]*\"[^\"]*\")*[^\"]*$)/g);
          } else {
            searchValues = input.split(/\s+/);
          }

          let textFilter = searchDefs
            .map((searchDef) => {
              return searchValues
                .map((value) => {
                  return `contains(tolower(${searchDef}), tolower('${value}'))`;
                })
                .join(` ${searchLogic} `); // this will separate multiple values
            })
            .join(" or "); // while this will separate multiple search defs

          odataFilter.push(`(${textFilter})`);
        }
      } else if (field === FullTermListFilterEnum.title) {
        odataFilter.push(
          `contains(tolower(title), tolower('${filterBy[field]}'))`,
        );
      } else if (
        // these filters are array of values and need to be mapped
        field === FullTermListFilterEnum.state ||
        field === FullTermListFilterEnum.type ||
        field === FullTermListFilterEnum.subjectMatter
      ) {
        let multipleValueFilters = newFilterObj[field]
          .map((state) => {
            return `contains(tolower(${field}), tolower('${state}'))`;
          })
          .join(" or ");
        odataFilter.push(`(${multipleValueFilters})`);
      } else if (field === FullTermListFilterEnum.companyId) {
        let multipleClubIds = newFilterObj[field]
          .map((clubId) => {
            return `companyId eq '${clubId}'`;
          })
          .join(" or ");
        odataFilter.push(`(${multipleClubIds})`);
      } else if (
        field === FullTermListFilterEnum.submissionDate ||
        field === FullTermListFilterEnum.agreementDate ||
        field === FullTermListFilterEnum.commencementDate ||
        field === FullTermListFilterEnum.submittedToPlDate
      ) {
        odataFilter.push(
          `${field} ge ${dayjs(filterBy[field][0])
            .utc()
            .startOf("day")
            .toISOString()} and ${field} le ${dayjs(filterBy[field][1])
            .utc()
            .startOf("day")
            .toISOString()}`,
        );
      } else if (
        field === FullTermListFilterEnum.territories ||
        field === FullTermListFilterEnum.brandSector
      ) {
        customQuery.push(
          ...newFilterObj[field].map(
            (value) => `${field}=${value.replace("&", "%26")}`,
          ),
        );
      } else if (field === FullTermListFilterEnum.number) {
        odataFilter.push(
          `number eq ${parseInt(
            filterBy[field].toString().replace(/[^\d.-]/g, ""),
          )}`,
        );
      }
    });

    // console.log("==>", odataFilter);
    // console.log("==>", customQuery.join("&"));

    //set current filter obj state
    setFilter(filterBy);
    // @ts-ignore
    setCustomQueryString(customQuery.join("&"));
    setOdataFilterString(odataFilter.join(" and "));

    //store odataFilterString in cache
    localStorage.setItem(
      "odataTermListFilterString",
      odataFilter.join(" and "),
    );

    //store odataFilterString in cache
    localStorage.setItem("customQueryString", customQuery.join("&"));

    //store odataFilterObj in cache
    localStorage.setItem("odataTermListFilterObj", JSON.stringify(filterBy));
  };

  const emptyFilterState = [...generalFilterFields, ...adminFilterFields].map(
    (field) => ({
      // it will complain that you cannot assign undefined
      // to "state" and "type" because they are enums
      // @ts-ignore
      [field.name]: undefined,
    }),
  );

  //clear filter
  const clearFilter = () => {
    localStorage.removeItem("odataTermListFilterString");
    localStorage.removeItem("odataTermListFilterObj");
    localStorage.removeItem("customQueryString");

    // @ts-ignore
    setFilter(...emptyFilterState);

    setSearchedText("");
    // @ts-ignore
    updateFilterString(...emptyFilterState);
  };

  //fetch data
  useEffect(() => {
    if (!!terms) {
      buildTableData();
    }

    if (termsCount) {
      setPagination({ ...pagination, total: termsCount });
    }
  }, [terms, termsCount]);

  const handleTableChange = (
    _: TablePaginationConfig, // do not use, pagination is being controlled by Pagination component
    __?: TermListFilter,
    sorter?: { field: keyof TermListFilter; order: string },
  ) => {
    localStorage.setItem("transactionPageSorter", JSON.stringify(sorter));
    setSorter(sorter);
  };

  const handlePageChange = (current: any, pageSize: any) => {
    // set current transactionPage in localstorage
    localStorage.setItem("transactionPage", current);
    localStorage.setItem("transactionPageSize", pageSize);

    setPagination({
      ...pagination,
      current,
      pageSize,
    });
  };

  return (
    <>
      {termModalVisible && (
        <DraftTransactionModal
          newDraftType={newDraftType}
          isModalVisible={termModalVisible}
          setIsModalVisible={setTermModalVisible}
        />
      )}
      {importModalVisible && (
        <ImportDataModal
          isModalVisible={importModalVisible}
          setIsModalVisible={() => {
            fetchTerms();
            setImportModalVisible(!importModalVisible);
          }}
        />
      )}
      <SPageHeader
        title="Transactions"
        breadcrumb={routes}
        extra={[
          <Button
            key={0}
            onClick={() => fetchTerms()}
            icon={<SyncOutlined />}
          />,
          (isUserAdmin || isUserClub) && (
            <CSVLink
              key={1}
              headers={headers}
              data={getDataForCsvExport(csvData as Term[])}
              filename={`Transactions-Report-${dayjs(new Date()).format(
                "DD-MM-YYYY LT",
              )}`}
            >
              <Button icon={<ExportOutlined />}>Export</Button>
            </CSVLink>
          ),
          isUserClub && (
            <Button
              key={2}
              onClick={() => showImportModal()}
              icon={<UploadOutlined />}
            >
              Import
            </Button>
          ),
          canCreateTerms && (
            <Dropdown
              key={3}
              menu={{
                // it complains that the key (string) is not assignable to TermType
                // @ts-ignore
                onClick: handleCreateNewMenuClick,
                items: [
                  {
                    key: TermType.databank,
                    icon: <InsertRowBelowOutlined />,
                    label: "Databank Transaction",
                  },
                  {
                    key: TermType.threshold,
                    icon: <InsertRowAboveOutlined />,
                    label: "Threshold Transaction",
                  },
                  {
                    key: TermType.associated,
                    icon: <UsergroupAddOutlined />,
                    label: "Associated Party Transaction",
                  },
                ],
              }}
            >
              <Button key={4} type="primary">
                <PlusOutlined /> Create Draft <DownOutlined />
              </Button>
            </Dropdown>
          ),
        ]}
      />
      <SPageContent>
        <FilterCard
          layout={{ lg: 8, md: 12, sm: 24, xs: 24 }}
          filterFields={getFilterFields()}
          clearFilter={clearFilter}
          filter={filter}
          setFilter={updateFilterString}
          setSearchedText={setSearchedText}
          emitSearchLogic={setSearchLogic}
        />
        <Card bodyStyle={{ paddingTop: "0" }} style={{ minWidth: "700px" }}>
          <TableToolbar title="" />
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={data}
            pagination={false}
            loading={loading}
            scroll={{ x: 1300 }}
            //@ts-ignore
            onChange={handleTableChange}
            onRow={(term) => {
              return {
                onClick: () => {
                  // clear audit filtering if you open a new term
                  localStorage.removeItem("odataTransactionListFilterString");
                  localStorage.removeItem("odataTransactionListFilterObj");

                  // navigate to new
                  navigate(`/transaction/${term.id}`);
                },
              };
            }}
            footer={() =>
              `${pageCount.startWindow}-${pageCount.endWindow} of ${pageCount.total} total record(s)`
            }
          />
          <Pagination
            style={{ marginTop: "10px", marginBottom: "10px" }}
            showSizeChanger
            defaultCurrent={pagination.current}
            pageSize={pagination.pageSize}
            total={termsCount}
            onChange={handlePageChange}
          />
        </Card>
      </SPageContent>
    </>
  );
};

export default TransactionList;
